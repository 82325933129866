/* tslint:disable */
/* eslint-disable */
/**
 * Clippd Spikemark API
 * # Overview Spikemark provides tournament scoring, real-time leader boards, detailed statistics, in-depth analytics and media through a platform that  includes easy-to-navigate screens allowing users to keep track of their favorite teams and players.  Clippd is an AI-focussed technology business building a connected future for golfers and coaches who have a passion for performance.  At our core is a universal platform that aggregates and elevates all the performance data created by millions of golfers and coaches every day.   This documentation provides the \"Spikemark API\", powered by the Clippd platform. The purpose of the API is to provide advanced analytics and  integration for rankings for collegiate golf.  In addition to this documentation, we also provide an [OpenAPI](https://github.com/OAI/OpenAPI-Specification/blob/master/versions/3.0.1.md)  specification describing the Clippd API Specification in [YAML](openapi.yaml) and [JSON](openapi.json) formats.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@clippd.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Request for creating and editing the Tournament
 * @export
 * @interface TournamentRequest
 */
export interface TournamentRequest {
  /**
   *
   * @type {string}
   * @memberof TournamentRequest
   */
  tournamentId?: string;
  /**
   *
   * @type {string}
   * @memberof TournamentRequest
   */
  tournamentName: string;
  /**
   *
   * @type {string}
   * @memberof TournamentRequest
   */
  gender: TournamentRequestGenderEnum;
  /**
   *
   * @type {string}
   * @memberof TournamentRequest
   */
  division: string;
  /**
   *
   * @type {string}
   * @memberof TournamentRequest
   */
  eventType: TournamentRequestEventTypeEnum;
  /**
   *
   * @type {string}
   * @memberof TournamentRequest
   */
  inviteType?: TournamentRequestInviteTypeEnum;
  /**
   *
   * @type {number}
   * @memberof TournamentRequest
   */
  season?: number;
  /**
   *
   * @type {Date}
   * @memberof TournamentRequest
   */
  startDate: Date;
  /**
   *
   * @type {Date}
   * @memberof TournamentRequest
   */
  endDate: Date;
  /**
   *
   * @type {string}
   * @memberof TournamentRequest
   */
  teeStyle?: TournamentRequestTeeStyleEnum;
  /**
   *
   * @type {string}
   * @memberof TournamentRequest
   */
  tournamentLogo?: string;
  /**
   *
   * @type {string}
   * @memberof TournamentRequest
   */
  tournamentKey?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof TournamentRequest
   */
  matchTypes?: Array<TournamentRequestMatchTypesEnum>;
  /**
   *
   * @type {Array<string>}
   * @memberof TournamentRequest
   */
  fourpeopleTypes?: Array<TournamentRequestFourpeopleTypesEnum>;
  /**
   *
   * @type {string}
   * @memberof TournamentRequest
   */
  venue?: string;
  /**
   *
   * @type {string}
   * @memberof TournamentRequest
   */
  venueId?: string;
  /**
   *
   * @type {string}
   * @memberof TournamentRequest
   */
  course?: string;
  /**
   *
   * @type {string}
   * @memberof TournamentRequest
   */
  city?: string;
  /**
   *
   * @type {string}
   * @memberof TournamentRequest
   */
  state?: string;
  /**
   *
   * @type {string}
   * @memberof TournamentRequest
   */
  hostId?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof TournamentRequest
   */
  permissedUsers?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof TournamentRequest
   */
  numRounds?: number;
  /**
   *
   * @type {number}
   * @memberof TournamentRequest
   */
  numTeamPlayers?: number;
  /**
   *
   * @type {number}
   * @memberof TournamentRequest
   */
  numTeamScoring?: number;
  /**
   *
   * @type {string}
   * @memberof TournamentRequest
   */
  dataSource?: string;
  /**
   *
   * @type {number}
   * @memberof TournamentRequest
   */
  roundsCompleted?: number;
  /**
   *
   * @type {string}
   * @memberof TournamentRequest
   */
  sponsor?: string;
  /**
   *
   * @type {string}
   * @memberof TournamentRequest
   */
  tournamentIntegrationId?: TournamentRequestTournamentIntegrationIdEnum;
  /**
   *
   * @type {boolean}
   * @memberof TournamentRequest
   */
  allowSubstitutes?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TournamentRequest
   */
  partnerFeed?: boolean;
  /**
   *
   * @type {string}
   * @memberof TournamentRequest
   */
  tournamentPrivacy?: TournamentRequestTournamentPrivacyEnum;
  /**
   *
   * @type {string}
   * @memberof TournamentRequest
   */
  tournamentLevel?: TournamentRequestTournamentLevelEnum;
  /**
   *
   * @type {string}
   * @memberof TournamentRequest
   */
  leaderboardMessage?: string;
  /**
   *
   * @type {string}
   * @memberof TournamentRequest
   */
  timezone?: string;
  /**
   *
   * @type {string}
   * @memberof TournamentRequest
   */
  shareCode?: string;
}

/**
 * @export
 */
export const TournamentRequestGenderEnum = {
  Men: 'Men',
  Women: 'Women',
  CombinedMenAndWomen: 'Combined Men and Women',
} as const;
export type TournamentRequestGenderEnum =
  (typeof TournamentRequestGenderEnum)[keyof typeof TournamentRequestGenderEnum];

/**
 * @export
 */
export const TournamentRequestEventTypeEnum = {
  StrokePlay: 'Stroke Play',
  MatchPlay: 'Match Play',
  MedalMatchPlay: 'Medal Match Play',
} as const;
export type TournamentRequestEventTypeEnum =
  (typeof TournamentRequestEventTypeEnum)[keyof typeof TournamentRequestEventTypeEnum];

/**
 * @export
 */
export const TournamentRequestInviteTypeEnum = {
  Team: 'team',
  Individual: 'individual',
} as const;
export type TournamentRequestInviteTypeEnum =
  (typeof TournamentRequestInviteTypeEnum)[keyof typeof TournamentRequestInviteTypeEnum];

/**
 * @export
 */
export const TournamentRequestTeeStyleEnum = {
  SingleTee: 'Single Tee',
  MultipleTee: 'Multiple Tee"',
  Shotgun: 'Shotgun',
} as const;
export type TournamentRequestTeeStyleEnum =
  (typeof TournamentRequestTeeStyleEnum)[keyof typeof TournamentRequestTeeStyleEnum];

/**
 * @export
 */
export const TournamentRequestMatchTypesEnum = {
  HeadToHead: 'HEAD_TO_HEAD',
  Bracket: 'BRACKET',
  Flights: 'FLIGHTS',
  NotSpecified: 'NOT_SPECIFIED',
} as const;
export type TournamentRequestMatchTypesEnum =
  (typeof TournamentRequestMatchTypesEnum)[keyof typeof TournamentRequestMatchTypesEnum];

/**
 * @export
 */
export const TournamentRequestFourpeopleTypesEnum = {
  Singles: 'SINGLES',
  Fourball: 'FOURBALL',
  Foursomes: 'FOURSOMES',
} as const;
export type TournamentRequestFourpeopleTypesEnum =
  (typeof TournamentRequestFourpeopleTypesEnum)[keyof typeof TournamentRequestFourpeopleTypesEnum];

/**
 * @export
 */
export const TournamentRequestTournamentIntegrationIdEnum = {
  Golfstat: 'golfstat',
  Golfgenius: 'golfgenius',
  Birdiefire: 'birdiefire',
  Smartgolf: 'smartgolf',
  Leaderboardking: 'leaderboardking',
  Scoreboardlive: 'scoreboardlive',
} as const;
export type TournamentRequestTournamentIntegrationIdEnum =
  (typeof TournamentRequestTournamentIntegrationIdEnum)[keyof typeof TournamentRequestTournamentIntegrationIdEnum];

/**
 * @export
 */
export const TournamentRequestTournamentPrivacyEnum = {
  Public: 'PUBLIC',
  Private: 'PRIVATE',
  ShareCode: 'SHARE_CODE',
} as const;
export type TournamentRequestTournamentPrivacyEnum =
  (typeof TournamentRequestTournamentPrivacyEnum)[keyof typeof TournamentRequestTournamentPrivacyEnum];

/**
 * @export
 */
export const TournamentRequestTournamentLevelEnum = {
  Tournament: 'TOURNAMENT',
  Qualifying: 'QUALIFYING',
  Practice: 'PRACTICE',
} as const;
export type TournamentRequestTournamentLevelEnum =
  (typeof TournamentRequestTournamentLevelEnum)[keyof typeof TournamentRequestTournamentLevelEnum];

/**
 * Check if a given object implements the TournamentRequest interface.
 */
export function instanceOfTournamentRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'tournamentName' in value;
  isInstance = isInstance && 'gender' in value;
  isInstance = isInstance && 'division' in value;
  isInstance = isInstance && 'eventType' in value;
  isInstance = isInstance && 'startDate' in value;
  isInstance = isInstance && 'endDate' in value;

  return isInstance;
}

export function TournamentRequestFromJSON(json: any): TournamentRequest {
  return TournamentRequestFromJSONTyped(json, false);
}

export function TournamentRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TournamentRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    tournamentId: !exists(json, 'tournamentId') ? undefined : json['tournamentId'],
    tournamentName: json['tournamentName'],
    gender: json['gender'],
    division: json['division'],
    eventType: json['eventType'],
    inviteType: !exists(json, 'inviteType') ? undefined : json['inviteType'],
    season: !exists(json, 'season') ? undefined : json['season'],
    startDate: new Date(json['startDate']),
    endDate: new Date(json['endDate']),
    teeStyle: !exists(json, 'teeStyle') ? undefined : json['teeStyle'],
    tournamentLogo: !exists(json, 'tournamentLogo') ? undefined : json['tournamentLogo'],
    tournamentKey: !exists(json, 'tournamentKey') ? undefined : json['tournamentKey'],
    matchTypes: !exists(json, 'matchTypes') ? undefined : json['matchTypes'],
    fourpeopleTypes: !exists(json, 'fourpeopleTypes') ? undefined : json['fourpeopleTypes'],
    venue: !exists(json, 'venue') ? undefined : json['venue'],
    venueId: !exists(json, 'venueId') ? undefined : json['venueId'],
    course: !exists(json, 'course') ? undefined : json['course'],
    city: !exists(json, 'city') ? undefined : json['city'],
    state: !exists(json, 'state') ? undefined : json['state'],
    hostId: !exists(json, 'hostId') ? undefined : json['hostId'],
    permissedUsers: !exists(json, 'permissedUsers') ? undefined : json['permissedUsers'],
    numRounds: !exists(json, 'numRounds') ? undefined : json['numRounds'],
    numTeamPlayers: !exists(json, 'numTeamPlayers') ? undefined : json['numTeamPlayers'],
    numTeamScoring: !exists(json, 'numTeamScoring') ? undefined : json['numTeamScoring'],
    dataSource: !exists(json, 'dataSource') ? undefined : json['dataSource'],
    roundsCompleted: !exists(json, 'roundsCompleted') ? undefined : json['roundsCompleted'],
    sponsor: !exists(json, 'sponsor') ? undefined : json['sponsor'],
    tournamentIntegrationId: !exists(json, 'tournamentIntegrationId')
      ? undefined
      : json['tournamentIntegrationId'],
    allowSubstitutes: !exists(json, 'allowSubstitutes') ? undefined : json['allowSubstitutes'],
    partnerFeed: !exists(json, 'partnerFeed') ? undefined : json['partnerFeed'],
    tournamentPrivacy: !exists(json, 'tournamentPrivacy') ? undefined : json['tournamentPrivacy'],
    tournamentLevel: !exists(json, 'tournamentLevel') ? undefined : json['tournamentLevel'],
    leaderboardMessage: !exists(json, 'leaderboardMessage')
      ? undefined
      : json['leaderboardMessage'],
    timezone: !exists(json, 'timezone') ? undefined : json['timezone'],
    shareCode: !exists(json, 'shareCode') ? undefined : json['shareCode'],
  };
}

export function TournamentRequestToJSON(value?: TournamentRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    tournamentId: value.tournamentId,
    tournamentName: value.tournamentName,
    gender: value.gender,
    division: value.division,
    eventType: value.eventType,
    inviteType: value.inviteType,
    season: value.season,
    startDate: value.startDate.toISOString().substring(0, 10),
    endDate: value.endDate.toISOString().substring(0, 10),
    teeStyle: value.teeStyle,
    tournamentLogo: value.tournamentLogo,
    tournamentKey: value.tournamentKey,
    matchTypes: value.matchTypes,
    fourpeopleTypes: value.fourpeopleTypes,
    venue: value.venue,
    venueId: value.venueId,
    course: value.course,
    city: value.city,
    state: value.state,
    hostId: value.hostId,
    permissedUsers: value.permissedUsers,
    numRounds: value.numRounds,
    numTeamPlayers: value.numTeamPlayers,
    numTeamScoring: value.numTeamScoring,
    dataSource: value.dataSource,
    roundsCompleted: value.roundsCompleted,
    sponsor: value.sponsor,
    tournamentIntegrationId: value.tournamentIntegrationId,
    allowSubstitutes: value.allowSubstitutes,
    partnerFeed: value.partnerFeed,
    tournamentPrivacy: value.tournamentPrivacy,
    tournamentLevel: value.tournamentLevel,
    leaderboardMessage: value.leaderboardMessage,
    timezone: value.timezone,
    shareCode: value.shareCode,
  };
}
