import(/* webpackMode: "eager", webpackExports: ["AdSlot"] */ "/app/apps/web/components/ads/ad-slot.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PreviewLeaderboardClient"] */ "/app/apps/web/components/tables/leaderboard/preview/preview-leaderboard-client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TournamentPreviewBody"] */ "/app/apps/web/components/tables/tournament/preview/tournament-preview-body.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["YouTubePlayer"] */ "/app/apps/web/components/youtube-player.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SchoolField"] */ "/app/apps/web/fields/school-field.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TournamentField"] */ "/app/apps/web/fields/tournament-field.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RichTextCell"] */ "/app/apps/web/node_modules/@payloadcms/richtext-slate/dist/cell/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Blockquote"] */ "/app/apps/web/node_modules/@payloadcms/richtext-slate/dist/field/elements/blockquote/Blockquote.js");
;
import(/* webpackMode: "eager" */ "/app/apps/web/node_modules/@payloadcms/richtext-slate/dist/field/elements/Button.js");
;
import(/* webpackMode: "eager", webpackExports: ["Heading1"] */ "/app/apps/web/node_modules/@payloadcms/richtext-slate/dist/field/elements/h1/Heading1.js");
;
import(/* webpackMode: "eager", webpackExports: ["Heading2"] */ "/app/apps/web/node_modules/@payloadcms/richtext-slate/dist/field/elements/h2/Heading2.js");
;
import(/* webpackMode: "eager", webpackExports: ["Heading3"] */ "/app/apps/web/node_modules/@payloadcms/richtext-slate/dist/field/elements/h3/Heading3.js");
;
import(/* webpackMode: "eager", webpackExports: ["Heading4"] */ "/app/apps/web/node_modules/@payloadcms/richtext-slate/dist/field/elements/h4/Heading4.js");
;
import(/* webpackMode: "eager", webpackExports: ["Heading5"] */ "/app/apps/web/node_modules/@payloadcms/richtext-slate/dist/field/elements/h5/Heading5.js");
;
import(/* webpackMode: "eager", webpackExports: ["Heading6"] */ "/app/apps/web/node_modules/@payloadcms/richtext-slate/dist/field/elements/h6/Heading6.js");
;
import(/* webpackMode: "eager", webpackExports: ["IndentButton"] */ "/app/apps/web/node_modules/@payloadcms/richtext-slate/dist/field/elements/indent/Button.js");
;
import(/* webpackMode: "eager", webpackExports: ["IndentElement"] */ "/app/apps/web/node_modules/@payloadcms/richtext-slate/dist/field/elements/indent/Element.js");
;
import(/* webpackMode: "eager", webpackExports: ["ListItemElement"] */ "/app/apps/web/node_modules/@payloadcms/richtext-slate/dist/field/elements/li/ListItem.js");
;
import(/* webpackMode: "eager", webpackExports: ["LinkButton"] */ "/app/apps/web/node_modules/@payloadcms/richtext-slate/dist/field/elements/link/Button/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["LinkElement"] */ "/app/apps/web/node_modules/@payloadcms/richtext-slate/dist/field/elements/link/Element/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["WithLinks"] */ "/app/apps/web/node_modules/@payloadcms/richtext-slate/dist/field/elements/link/WithLinks.js");
;
import(/* webpackMode: "eager" */ "/app/apps/web/node_modules/@payloadcms/richtext-slate/dist/field/elements/ListButton.js");
;
import(/* webpackMode: "eager", webpackExports: ["OrderedList"] */ "/app/apps/web/node_modules/@payloadcms/richtext-slate/dist/field/elements/ol/OrderedList.js");
;
import(/* webpackMode: "eager", webpackExports: ["Button"] */ "/app/apps/web/node_modules/@payloadcms/richtext-slate/dist/field/elements/relationship/Button/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Element"] */ "/app/apps/web/node_modules/@payloadcms/richtext-slate/dist/field/elements/relationship/Element/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["WithRelationship"] */ "/app/apps/web/node_modules/@payloadcms/richtext-slate/dist/field/elements/relationship/plugin.js");
;
import(/* webpackMode: "eager", webpackExports: ["toggleElement"] */ "/app/apps/web/node_modules/@payloadcms/richtext-slate/dist/field/elements/toggle.js");
;
import(/* webpackMode: "eager", webpackExports: ["UnorderedList"] */ "/app/apps/web/node_modules/@payloadcms/richtext-slate/dist/field/elements/ul/UnorderedList.js");
;
import(/* webpackMode: "eager", webpackExports: ["Button"] */ "/app/apps/web/node_modules/@payloadcms/richtext-slate/dist/field/elements/upload/Button/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Element"] */ "/app/apps/web/node_modules/@payloadcms/richtext-slate/dist/field/elements/upload/Element/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["WithUpload"] */ "/app/apps/web/node_modules/@payloadcms/richtext-slate/dist/field/elements/upload/plugin.js");
;
import(/* webpackMode: "eager", webpackExports: ["RichTextField"] */ "/app/apps/web/node_modules/@payloadcms/richtext-slate/dist/field/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Bold"] */ "/app/apps/web/node_modules/@payloadcms/richtext-slate/dist/field/leaves/bold/Bold/index.js");
;
import(/* webpackMode: "eager" */ "/app/apps/web/node_modules/@payloadcms/richtext-slate/dist/field/leaves/Button.js");
;
import(/* webpackMode: "eager", webpackExports: ["Code"] */ "/app/apps/web/node_modules/@payloadcms/richtext-slate/dist/field/leaves/code/Code/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Italic"] */ "/app/apps/web/node_modules/@payloadcms/richtext-slate/dist/field/leaves/italic/Italic/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Strikethrough"] */ "/app/apps/web/node_modules/@payloadcms/richtext-slate/dist/field/leaves/strikethrough/Strikethrough/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Underline"] */ "/app/apps/web/node_modules/@payloadcms/richtext-slate/dist/field/leaves/underline/Underline/index.js");
;
import(/* webpackMode: "eager" */ "/app/apps/web/node_modules/@payloadcms/ui/dist/elements/ViewDescription/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["HiddenInput"] */ "/app/apps/web/node_modules/@payloadcms/ui/dist/fields/HiddenInput/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["FieldDescription"] */ "/app/apps/web/node_modules/@payloadcms/ui/dist/forms/FieldDescription/index.js");
;
import(/* webpackMode: "eager" */ "/app/apps/web/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/packages/core/src/cache/client-cache-provider.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/core/src/config/client-config-provider.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/components/avatar.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/components/card.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/components/cloudinary-image.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/components/cloudinary-video.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/components/mascot.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Carousel","CarouselContent","CarouselItem","CarouselPrevious","CarouselNext"] */ "/app/packages/ui/components/ui/carousel.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/components/ui/tabs.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/components/ui/tooltip.tsx");
