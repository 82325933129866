import { FC } from 'react';
import { HeaderContainer } from './header-container';
import { BackButton } from './back-button';
import { MobileNavContainer } from './navigation/navigation-mobile';
import { NavLinks } from './navigation/nav-links';
import { BaseLink as Link } from '../components/navigation/entity-links';
import { Brand } from './brand';
import classNames from 'classnames';
import { Route } from './navigation/types';
type HeaderProps = {
  topLevelNavigation: Route[];
  isCapacitor?: boolean;
  rightContent?: React.ReactNode;
  expandedContent?: React.ReactNode;
};
export const HeaderMobile: FC<HeaderProps> = ({
  topLevelNavigation,
  isCapacitor,
  rightContent,
  expandedContent
}) => {
  return <>
      {/* This div is to cover the notch area on iOS */}
      <div className="fixed bg-white" style={{
      top: -100,
      left: 0,
      right: 0,
      height: 100,
      zIndex: 1000
    }} />
      <HeaderContainer className="z-[40] fixed border-zinc-200 border-b bg-white drop-shadow-sm" data-sentry-element="HeaderContainer" data-sentry-source-file="header-mobile.tsx">
        <div className={classNames('w-[60px]', {
        hidden: !isCapacitor
      })}>
          <BackButton data-sentry-element="BackButton" data-sentry-source-file="header-mobile.tsx" />
        </div>
        <div className="pl-1 items-center justify-between flex-1 flex">
          <Link href="/" data-sentry-element="Link" data-sentry-source-file="header-mobile.tsx">
            <Brand variant="small" data-sentry-element="Brand" data-sentry-source-file="header-mobile.tsx" />
          </Link>
          {rightContent}
        </div>
        <nav className="w-[44px]">
          <MobileNavContainer data-sentry-element="MobileNavContainer" data-sentry-source-file="header-mobile.tsx">
            <NavLinks routes={topLevelNavigation} className="text-zinc-200 text-base active:bg-black active:bg-opacity-20 focus:bg-black focus:bg-opacity-20" activeClassName="text-semibold text-clippd-pink text-base" data-sentry-element="NavLinks" data-sentry-source-file="header-mobile.tsx" />
            <div className="absolute top-[84vh] left-0 right-0 flex justify-center items-center">
              {expandedContent}
            </div>
          </MobileNavContainer>
        </nav>
      </HeaderContainer>
    </>;
};