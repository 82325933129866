import { BaseLink as Link } from '../components/navigation/entity-links';
import { FC, ReactNode } from 'react';
import { Brand } from './brand';
import { HeaderContainer } from './header-container';
import { DesktopNavContainer } from './navigation/navigation-desktop';
import { NavLinks } from './navigation/nav-links';
import { Route } from './navigation/types';
type HeaderProps = {
  topLevelNavigation: Route[];
  rightContent?: ReactNode;
};
export const HeaderDesktop: FC<HeaderProps> = ({
  topLevelNavigation,
  rightContent
}) => {
  return <HeaderContainer className="fixed border-zinc-200 border-b bg-white drop-shadow-sm" data-sentry-element="HeaderContainer" data-sentry-component="HeaderDesktop" data-sentry-source-file="header-desktop.tsx">
      <Link href="/" className="block shrink-0" data-sentry-element="Link" data-sentry-source-file="header-desktop.tsx">
        <Brand data-sentry-element="Brand" data-sentry-source-file="header-desktop.tsx" />
      </Link>
      <nav className="mx-4 h-full flex flex-1">
        <DesktopNavContainer data-sentry-element="DesktopNavContainer" data-sentry-source-file="header-desktop.tsx">
          <NavLinks routes={topLevelNavigation} className="text-primary font-bold h-full hover:bg-zinc-100 transition-colors duration-200 place-items-center pt-3 border-b-4 border-transparent" activeClassName="text-primary font-bold pt-3 border-b-4 border-clippd-pink place-items-center" data-sentry-element="NavLinks" data-sentry-source-file="header-desktop.tsx" />
        </DesktopNavContainer>
      </nav>
      {rightContent}
    </HeaderContainer>;
};