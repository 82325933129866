import { FC } from 'react';
import { BreakpointSwitch } from '../components/breakpoint-switch';
import { HeaderDesktop } from './header-desktop';
import { HeaderMobile } from './header-mobile';
import { Route } from './navigation/types';
interface HeaderProps {
  topLevelNavigation: Route[];
  isCapacitor?: boolean;
  rightContent?: React.ReactNode;
  mobileRightContent?: React.ReactNode;
  mobileExpandedContent?: React.ReactNode;
}
export const Header: FC<HeaderProps> = ({
  topLevelNavigation,
  isCapacitor,
  rightContent,
  mobileExpandedContent,
  mobileRightContent
}) => {
  return <BreakpointSwitch breakpoint="lg" fallback={<HeaderMobile topLevelNavigation={topLevelNavigation} isCapacitor={isCapacitor} rightContent={mobileRightContent} expandedContent={mobileExpandedContent} />} data-sentry-element="BreakpointSwitch" data-sentry-component="Header" data-sentry-source-file="header.tsx">
      <HeaderDesktop topLevelNavigation={topLevelNavigation} rightContent={rightContent} data-sentry-element="HeaderDesktop" data-sentry-source-file="header.tsx" />
    </BreakpointSwitch>;
};