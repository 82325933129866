'use client';

import { Capacitor } from '@capacitor/core';
import { Header } from '@spikemark/ui/shell/header';
import { Route } from '@spikemark/ui/shell/navigation/types';
import { TaylorMadeLogo } from '@spikemark/ui/partnerships/taylor-made';
import { FC } from 'react';
export const AppHeaderContent: FC<{
  routes: Route[];
  sponsorshipEnabled?: boolean;
}> = ({
  routes,
  sponsorshipEnabled
}) => {
  return <div className="print:hidden" data-sentry-component="AppHeaderContent" data-sentry-source-file="app-header-content.tsx">
      <Header topLevelNavigation={routes} isCapacitor={Capacitor.isNativePlatform()} rightContent={sponsorshipEnabled && <TaylorMadeLogo />} mobileRightContent={sponsorshipEnabled && <TaylorMadeLogo variant="small" />} mobileExpandedContent={sponsorshipEnabled && <TaylorMadeLogo dark variant="large" />} data-sentry-element="Header" data-sentry-source-file="app-header-content.tsx" />
    </div>;
};