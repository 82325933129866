'use client';

import Script from 'next/script';
import { FC, useCallback, useMemo } from 'react';
const SCRIPT_SRC_BASE = 'https://app.termly.io';
export const TermlyCMP: FC<{
  autoBlock?: boolean;
  masterConsentsOrigin?: string;
  websiteUUID: string;
}> = ({
  autoBlock,
  masterConsentsOrigin,
  websiteUUID
}) => {
  const scriptSrc = useMemo(() => {
    const src = new URL(SCRIPT_SRC_BASE);
    src.pathname = `/resource-blocker/${websiteUUID}`;
    if (autoBlock) {
      src.searchParams.set('autoBlock', 'on');
    }
    if (masterConsentsOrigin) {
      src.searchParams.set('masterConsentsOrigin', masterConsentsOrigin);
    }
    return src.toString();
  }, [autoBlock, masterConsentsOrigin, websiteUUID]);
  const handleLoad = useCallback(() => {
    if ('Termly' in window) {
      const Termly = window.Termly as any;
      Termly.initialize();
    }
  }, []);
  return <Script src={scriptSrc} onLoad={handleLoad} data-sentry-element="Script" data-sentry-component="TermlyCMP" data-sentry-source-file="termly-cmp.tsx" />;
};