import type { IClientConfig } from '@spikemark/core';
import type { MergeDeep } from 'type-fest';

export type IServerConfig = MergeDeep<
  Omit<IClientConfig, 'baseUrl' | 'supabase' | 'mixpanel' | 'stripe' | 'webAppUrl'>,
  {
    environment: string;
    restApiBaseUrl: string;
    appBaseUrl: string;
    version: string;
    sharpPath: string;
    cacheToken: string;
    elastic: {
      endpoint: string;
      credentials: {
        username: string;
        password: string;
      };
      logResults: boolean;
    };
    openWeather: {
      apiKey: string;
    };
    launchDarkly: {
      sdkKey: string;
    };
    coachPortalUrl: string;
    live: {
      appStoreUrl: string;
      playStoreUrl: string;
    };
    termly: {
      autoBlock?: boolean;
      masterConsentsOrigin?: string;
      websiteUUID: string;
      cookiePolicyUUID: string;
      apiKey: string;
    };
  }
>;

export const ServerConfig: IServerConfig = {
  environment: process.env.ENVIRONMENT || 'local',
  appBaseUrl: process.env.NEXT_PUBLIC_SITE_URL ?? '',
  restApiBaseUrl: process.env.REST_API_BASE_URL ?? '',
  version: process.env.PROJ_VERSION ?? 'local',
  disableCache: process.env.DISABLE_CACHE === 'true',
  cacheRevalidate: parseInt(process.env.CACHE_REVALIDATE ?? '1800', 10),
  launchDarkly: {
    sdkKey: process.env.LAUNCHDARKLY_SDK_KEY ?? '',
  },
  sharpPath: process.env.NEXT_SHARP_PATH ?? '',
  cloudinary: {
    basePath: process.env.CLOUDINARY_BASE_PATH ?? '',
  },
  cacheToken: process.env.CACHE_TOKEN ?? '',
  googleAnalytics: {
    gTag: process.env.GOOGLE_ANALYTICS_GTAG ?? '',
  },
  fetchRequestTimeout: parseInt(process.env.FETCH_REQUEST_TIMEOUT ?? '30000', 10),
  fetchRequestMaxRetries: parseInt(process.env.FETCH_REQUEST_MAX_RETRIES ?? '5', 10),
  elastic: {
    endpoint: process.env.ELASTIC_NODE_ENDPOINT as string,
    credentials: {
      username: process.env.ELASTIC_NODE_USERNAME as string,
      password: process.env.ELASTIC_NODE_PASSWORD as string,
    },
    logResults: false,
  },
  openWeather: {
    apiKey: process.env.OPEN_WEATHER_API_KEY as string,
  },
  // TODO: Hard-coded values need to be added to env vars once DevOps is back
  termly: {
    websiteUUID: 'dc09f6bb-b786-4ff7-86f2-38c07bfa1767', // process.env.TERMLY_WEBSITE_UUID as string,
    cookiePolicyUUID: 'eeecc420-2ee7-429d-84b1-4be7a330e2cc', // process.env.TERMLY_COOKIE_POLICY_UUID as string,
    apiKey:
      'eyJhbGciOiJIUzI1NiJ9.IklEK2c1Q0xwalFBQnZ5clVjUzV4Ukt4K2RzU0lFSVlLay9PbWsyUkVtSzdocXo4TzlhNTQwRjBaalJvTi9LZFBSSnlLUUFzRVZ2Vk5ZckN2R3RueE5PcVg1N3ZjaTY5YXdxTEJNZCtEZ253eVRTLzV3dXFoUzFRcHRXdThHaEdFRW1OOUs2RlItLStJZm42YkwwRGFucisxRTMtLU12dDB5OW5YZGxmK0VuSFpEaXpTRnc9PSI.NbR6uAPFOqJHYrbcXfdh9JjxH0CaI-1L2RA_RvnlhV4', // process.env.TERMLY_API_KEY as string,
  },
  coachPortalUrl: process.env.COACH_PORTAL_URL ?? '',
  scoreboardLiveLearnMoreUrl: process.env.SCOREBOARD_LIVE_LEARN_MORE_URL ?? '',
  helpCentreUrl: process.env.HELP_CENTRE_URL ?? '',
  // TODO: Hard-coded values need to be added to env vars once DevOps is back
  live: {
    appStoreUrl: 'https://apps.apple.com/us/app/scoreboard-live-scoring/id6503615924', // process.env.SCOREBOARD_LIVE_APP_STORE_URL ?? '',
    playStoreUrl: 'https://play.google.com/store/apps/details?id=com.clippd.scoreboard.scoring', // process.env.SCOREBOARD_LIVE_PLAY_STORE_URL ?? '',
  },
};
