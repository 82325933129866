'use client';

import { FC } from 'react';
import { Capacitor } from '@capacitor/core';
import { NativeFooter } from '@spikemark/ui/components/native-footer';
import { Footer } from './footer';
export const AppFooter: FC<{
  advertisingEnabled?: boolean;
  sponsorshipEnabled?: boolean;
}> = ({
  advertisingEnabled,
  sponsorshipEnabled
}) => {
  return Capacitor.isNativePlatform() ? <NativeFooter data-sentry-element="NativeFooter" data-sentry-component="AppFooter" data-sentry-source-file="app-footer.tsx" /> : <Footer advertisingEnabled={advertisingEnabled} sponsorshipEnabled={sponsorshipEnabled} data-sentry-element="Footer" data-sentry-component="AppFooter" data-sentry-source-file="app-footer.tsx" />;
};