import { TableHeaderCellProps } from './table-header-cell';
import { TableCellProps } from './table-cell';
import { cn } from '../../utils/cn';

export function getCellClassName(
  {
    className,
    column,
    columnIndex,
    compact,
    isFirstColumn,
    isLastColumn,
    stickyLeftClassName = 'left-0',
    rowData,
    rowIndex,
    rowSpan,
  }: TableCellProps<any>,
  type: 'header' | 'subHeader' | 'cell' = 'cell'
) {
  return cn(
    'align-middle group',
    {
      'px-1 py-1 print:px-2': compact,
      'px-1 lg:px-2 xl:px-3 2xl:px-4 print:px-4 py-1 lg:py-2 print:py-2': !compact,
    },
    className,
    typeof column.cellClassName === 'function' && type === 'cell'
      ? column.cellClassName({
          column,
          columnIndex,
          rowData,
          rowIndex,
          rowSpan,
        })
      : column.cellClassName,
    {
      'bg-inherit sticky z-20': column.sticky,
      [stickyLeftClassName]: column.sticky,
    }
  );
}

export function getHeaderCellClassName(
  options: TableHeaderCellProps<any>,
  type: 'header' | 'subHeader'
) {
  const { column, columnIndex, headerCellClassName } = options;

  return cn(
    getCellClassName(options as TableCellProps<any>, type),
    typeof headerCellClassName === 'function'
      ? headerCellClassName({
          column,
          columnIndex,
        })
      : headerCellClassName,
    column.headerCellClassName,
    {
      'cursor-pointer hover:underline whitespace-nowrap': column.sortable && type === 'header',
      'font-bold': type === 'header',
    }
  );
}
