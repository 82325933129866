'use client';

import { AdSize, ViewportSize } from '@/core/ad-config';
import { useGooglePublisherTags } from '@/providers/google-publisher-tags-provider';
import { AdTargetingMap } from '@/types/advertising';
import { cn } from '@spikemark/ui/utils/cn';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import { FC, useEffect, useId } from 'react';
export type AdSlotProps = {
  adUnitPath: string;
  adUnitId: string;
  sizeMappings: Array<{
    viewport: ViewportSize;
    sizes: AdSize[];
  }>;
  targeting?: AdTargetingMap;
  location?: string;
  className?: string;
  unitClassName?: string;
  sticky?: boolean;
  heavy?: boolean;
};
export const AdSlot: FC<AdSlotProps> = ({
  adUnitPath,
  adUnitId,
  sizeMappings,
  heavy,
  targeting,
  location,
  // TODO: Leave this here for now, but how should we handle location?
  className,
  unitClassName,
  sticky
}) => {
  const {
    googletag,
    availability,
    stickyAdSlotsEnabled
  } = useGooglePublisherTags();
  const divId = useId();
  const enabled = availability !== 'off' && (!heavy || availability === 'heavy');
  useEffect(() => {
    if (enabled && googletag && googletag.apiReady) {
      let slot: googletag.Slot | null = null;
      const orderedSizeMappings = orderBy(sizeMappings, x => x.viewport[0], 'desc');
      googletag.cmd.push(() => {
        const allSizes = uniqBy(orderBy(sizeMappings.flatMap(item => item.sizes), [x => x[0], x => x[1]], ['desc', 'desc']), size => size.join('x'));
        const sizeMapping: googletag.SizeMappingBuilder = googletag.sizeMapping();
        slot = googletag.defineSlot(adUnitPath, allSizes, divId);
        if (slot) {
          slot.addService(googletag.pubads());
          if (targeting) {
            Object.entries(targeting).forEach(([key, value]) => {
              if (value) {
                slot?.setTargeting(key, value);
              }
            });
          }
          googletag.display(divId);
        }
        for (const mapping of orderedSizeMappings) {
          const {
            viewport,
            sizes
          } = mapping;
          sizeMapping.addSize(viewport, sizes);
        }
        const mapping = sizeMapping.build();
        if (slot && mapping) {
          slot.defineSizeMapping(mapping).addService(googletag.pubads());
          googletag.pubads().refresh([slot], {
            changeCorrelator: false
          });
        }
      });
      return () => {
        googletag?.cmd.push(() => {
          if (slot) {
            googletag.destroySlots([slot]);
            slot = null;
          }
        });
      };
    }
  }, [enabled, googletag, adUnitPath, sizeMappings, targeting, divId, adUnitId, googletag?.apiReady]);
  return enabled ? <div className={cn('print:hidden shrink-0 text-center', className, {
    'border-b border-gray-200 sticky shadow-sm top-main-nav-mobile lg:top-main-nav z-30 backdrop-blur-md bg-white bg-opacity-80': sticky && stickyAdSlotsEnabled
  })} data-sentry-component="AdSlot" data-sentry-source-file="ad-slot.tsx">
      <div id={divId} className={cn('m-auto my-2 md:my-4 lg:my-6 inline-flex items-center box-content empty:hidden', unitClassName)} data-ad-unit-path={adUnitPath} data-size={JSON.stringify(sizeMappings)} data-availability={availability} data-targeting={JSON.stringify(targeting)}></div>
    </div> : null;
};